import React from 'react';
import Home from './pages/Home';
import TagStatus from './pages/TagStatus';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

const App: React.FC = () => {
  return (
      <Router>
        <Switch>
          <Route exact path = "/" component = {Home} />
          <Route path = "/tagStatus/:tagId" component = {TagStatus} />
        </Switch>
      </Router>
  );
};

export default App;
