import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import firebaseInit from '../common/firebaseInit';
import './TagStatus.css';
import '../components/TagStatusUnknown';
import TagStatusUnknown from "../components/TagStatusUnknown";
import TagStatusNotAssigned from "../components/TagStatusNotAssigned";
import TagStatusActive from "../components/TagStatusActive";
import TagStatusReadingInProgress from "../components/TagStatusReadingInProgress";

export interface Props {
    history: any,
    location: any,
    match: any
}

interface NfcTag {
    owner: string,
    name: string,
    description?: string,
    status: string
}

export interface State {
    reading: boolean,
    tagId: string | null,
    nfcTag: NfcTag | null
}

class TagStatus extends React.Component<Props, State> {
    tagId : string;
    firebaseApp : firebase.app.App;
    constructor(props: Props) {
        super(props);
        this.state = {reading: false, tagId: null, nfcTag: null};
        this.tagId = props.match.params.tagId;
        this.firebaseApp = firebaseInit();
    }

    componentDidMount(): void {
        let httpsCallable =  this.firebaseApp.functions('europe-west1').httpsCallable('getTagStatus');
        this.setState( {reading: true});
        httpsCallable.call(this,{tagId: this.tagId}).then((response) => {
            console.log(`Sucessfully called firebase function: ${response}`);
            let newState = {tagId : response.data.id, nfcTag: response.data, reading: false };
            this.setState(newState);
        }).catch((error) => {
            console.log(`Error calling firebase function: ${error}`);
            let newState = {tagId : null, nfcTag: null, reading: false };
            this.setState(newState);
        });
    }

    render() {
        return (
            <div className="App">
                {!this.state.nfcTag && !this.state.reading && <TagStatusUnknown tagId={this.tagId} /> }
                {!this.state.nfcTag && this.state.reading && <TagStatusReadingInProgress tagId={this.tagId} /> }
                {(this.state.nfcTag && this.state.nfcTag.status === 'NOT_ASSIGNED') && <TagStatusNotAssigned tagId={this.tagId} /> }
                {(this.state.nfcTag && this.state.nfcTag.status === 'ACTIVE') && <TagStatusActive tagId={this.tagId} tag={this.state.nfcTag} /> }
                {(this.state.nfcTag && this.state.nfcTag.status === 'LOST') && <TagStatusActive tagId={this.tagId} tag={this.state.nfcTag} /> }
            </div>
        );
    }
}

export default TagStatus;
