import React from 'react';
import './TagStatusUnknown.css';

export interface Props {
    tagId?: string
}

const TagStatusUnknown: React.FC<Props> = (props) => {
    return (
        <div className="TagStatusUnknown">
            Es tut uns Leid, aber dieser NFC-Tag ({props.tagId}) ist uns nicht bekannt.
        </div>
    );
};

export default TagStatusUnknown;
