import React from 'react';
import './TagStatusNotAssigned.css';

export interface Props {
    tagId?: string
}

const TagStatusNotAssigned: React.FC<Props> = (props) => {
    return (
        <div className="TagStatusNotAssigned">
            Dieser Tag ist noch leer und muss erst beschrieben werden.
        </div>
    );
};

export default TagStatusNotAssigned;
