import React from 'react';
import './TagStatusUnknown.css';

export interface Props {
    tagId?: string
}

const TagStatusReadingInProgress: React.FC<Props> = (props) => {
    return (
        <div className="TagStatusUnknown">
            Tag-Status wird gerade ermittelt.
        </div>
    );
};

export default TagStatusReadingInProgress;
