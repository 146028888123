import React from 'react';
import './TagStatusActive.css';
import firebaseInit from "../common/firebaseInit";

export interface Props {
    tagId: string
    tag: any
}

function contactOwner(tagId: string) {
    let httpsCallable =  firebaseInit().functions('europe-west1').httpsCallable('sendMessageToOwner');

    httpsCallable.call(firebaseInit(),{tagId: tagId, message: 'Message from owner.'}).
    then((response) => {
    });
}

const TagStatusActive: React.FC<Props> = (props) => {
    return (
        <div className="TagStatusNotAssigned">
            <div>Bezeichnung: {props.tag.name}</div>
            <div>Beschreibung: {props.tag.description}</div>
            <div>Besitzer: {props.tag.owner}</div>
            <br />
            <button onClick={() => contactOwner(props.tagId)}>Besitzer kontaktieren</button>
        </div>
    );
};

export default TagStatusActive;
